.root {
    container-type: inline-size;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-core-l);
    box-shadow: 0 15px 18px 0 rgb(0 0 0 / 5%);
    text-decoration: none;
    color: var(--color-foreground-primary-default);
    overflow: hidden;
    transition: box-shadow .2s cubic-bezier(.4,0,.2,1);

    &:hover,
    &:focus-visible {
        cursor: pointer;
        box-shadow: 0 15px 18px 0 rgb(0 0 0 / 15%);
    }
}
