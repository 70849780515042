.cta {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-primary-default);
    color: #303030;
    border: none;
    border-radius: 100%;
    width: var(--sizing-core-12);
    height: var(--sizing-core-12);
    transition: box-shadow .2s ease-in-out;
    box-shadow: none;
    user-select: none;

    &:hover,
    &:focus-visible {
        cursor: pointer;
        box-shadow: 0 8px 16px rgb(0 0 0 / 16%);

        svg {
            opacity: .7;
        }
    }

    svg {
        opacity: .4;
        width: var(--sizing-core-6);
        height: var(--sizing-core-6);
    }
}

.selected  {
    svg {
        opacity: 1;
    }

    &:hover {
        svg {
            opacity: initial;
        }
    }
}

.top {
    &-left {
        top: var(--sizing-core-4);
        left: var(--sizing-core-4);
    }

    &-right {
        top: var(--sizing-core-4);
        right: var(--sizing-core-4);
    }
}
