.item {
    padding: var(--spacing-core-2) 0;
    border-bottom: 1px solid #e9e9e9;
}

.ingredient {
    color: #838383;
}


