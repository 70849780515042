.ingredient {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-core-6) 0;

    &:hover {
        cursor: pointer;
    }
}
