@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    width: 2px;
    height: 24px;
    background: var(--color-action-primary-default);
    margin: var(--spacing-core-2) auto;
}

@include mq.mq($from: medium) {
    .root {
        margin-top: var(--spacing-core-4);
        margin-bottom: var(--spacing-core-4);
    }
}

@include mq.mq($from: large) {
    .root {
        margin-top: var(--spacing-core-6);
        margin-bottom: var(--spacing-core-6);
    }
}
