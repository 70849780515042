.content {
    box-sizing: content-box;
    padding: var(--sizing-core-5);
    flex-grow: 1;
    overflow: hidden;

    @container (width > 300px) {
        padding-left: var(--sizing-core-6);
        padding-right: var(--sizing-core-6);
    }

    p {
        line-height: 1.2em;
        max-height: 2.4em;
        height: 100%;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }   
}
